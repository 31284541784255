import User from'../../components/User';
import AccountPage from'../../components/accountpage/AccountPage';

export default function Userpage() {
  return (
    <>
      <User />
      <AccountPage />
    </>
  );
}
