import User from '../../components/User'
import Userplaces from '../../components/userplaces/Userplaces';
import Footer from '../../components/footer/Footer'

const Myplaces = () => {
  return (
    <>
      <User />
      <Userplaces />
      <Footer />
    </>
  )
}

export default Myplaces;
